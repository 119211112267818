.App {
  text-align: center;
  background-color: #000;
}

.App-logo {
  width: 85%;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.slogan {
  color: #ffffff;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 10%;
}

.logo-container {
  position: relative;
}

@media only screen and (max-width: 500px) {
  .slogan {
    bottom: 0%;
    font-size: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .slogan {
    bottom: 5%;
  }
}
